@use "../../../../styles/mixins";

.detailActionContainer {
  display: flex;
  column-gap: 0.5rem;
}

.content {
  display: flex;
  flex-direction: column;

  .tabCardContainer {
    @include mixins.tab-container;
  }

  .sectionTitle {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
  }
}

.detailSection {
  display: flex;
  flex-direction: column;

  &.multiple {
    row-gap: 24px;
  }

  .sectionContent {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;

    & > * {
      width: 50%;
    }

    &.small {
      > * {
        width: 25%;
      }
    }

    &.small-25-75 {
      > *:nth-child(1) {
        width: 25%;
      }
      > *:nth-child(2) {
        width: 75%;
      }
    }

    &.full > * {
      width: 100%;
    }

    .itemContainer {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .itemLabel {
        font-size: 14px;
      }

      .itemValue {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
